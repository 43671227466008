import React from "react";
// import { Link } from "react-router-dom";
import FormOne from "../contact/FormOne";

const BannerFive = () => {
	const handleMouseEnter = () => {
        document.getElementById('header-shadow').classList.add('banner-shadow');
    };

    const handleMouseLeave = () => {
        document.getElementById('header-shadow').classList.remove('banner-shadow');
    };
	return (
		// <div className="banner banner-style-5" style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/images/banner/banner-bg-1.png"})`}}>
		<div className="banner banner-style-5"
		onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
		>
			<video width="100%" height="900px" autoPlay muted loop controls className="d-none d-lg-block">
				<source
					src={
						process.env.PUBLIC_URL +
						"/images/corporate-websites.mp4"
					}
					type="video/mp4"
				/>
				Your browser does not support the video tag.
			</video>

			<div className="container get-quote">
				<div className="row">
                <div className="col-lg-6 col-xl-5">
						<div className="banner-form">
							<div className="contact-form-box shadow-box">
								<h3 className="title">
									Technology and Design Studio
                                    Get a Free quote now.
								</h3>
								<FormOne />
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-xl-7">
						<div className="banner-content">
							{/* <h1 className="title">
								Technology &amp; <br></br>design Studio
							</h1> */}
							{/* <div>
								<Link
									to={
										process.env.PUBLIC_URL +
										"/project-grid-one"
									}
									className="axil-btn btn-fill-white btn-large">
									View Showcase
								</Link>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BannerFive;
