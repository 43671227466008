import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
	return (
		<section className="section section-padding-equal bg-color-light">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="about-us">
							<div className="section-heading heading-left mb-0">
								<span className="subtitle">About Us</span>
								<h2 className="title mb--40">
									We do design, code &amp; develop.
								</h2>
								<p>
									At TekDisplay, creativity meets
									technical ability. Our team thrives on
									crafting visually stunning and user-friendly
									designs, ensuring your website not only
									looks great but also provides an exceptional
									user experience.
								</p>{" "}
								<p>
								We're not just about pretty
									faces; our designs are backed by robust
									coding and development practices. Whether
									it's a sleek portfolio site or a complex
									platform, we bring your vision to
									life with a seamless blend of aesthetics and
									functionality.{" "}
								</p>
							</div>
						</div>
					</div>

					<div className="col-xl-5 col-lg-6 offset-xl-1">
						<div className="contact-form-box">
							<h3 className="title">
								Get a free quote now
							</h3>
							<FormOne />
						</div>
					</div>
				</div>
			</div>
			<ul className="shape-group-6 list-unstyled">
				{/* <li className="shape shape-1">
					<img
						src={
							process.env.PUBLIC_URL +
							"/images/others/bubble-7.png"
						}
						alt="Bubble"
					/>
				</li> */}
				<li className="shape shape-2">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-4.png"
						}
						alt="line"
					/>
				</li>
				<li className="shape shape-3">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-5.png"
						}
						alt="line"
					/>
				</li>
			</ul>
		</section>
	);
};

export default AboutOne;
