import React from 'react';
// import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerFour from '../component/banner/BannerFour';
// import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
// import ProjectFour from '../component/project/ProjectFour';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SplashFooter from '../common/footer/SplashFooter';
// import BannerThree from '../component/banner/BannerThree';

const HomeStartup = () => {

    return (
        <>
        <SEO title="Home Startup"/>
        <ColorSwitcher />
        <main className="main-wrapper">
        <HeaderTwo />
        <BannerFour/>
        {/* <ProjectFour /> */}
        <TestimonialOne />
        <BrandOne />
        {/* <BlogOne /> */}
        <CtaLayoutOne />
        {/* <FooterOne /> */}
        <SplashFooter/>
        </main>
        </>
    )
}

export default HomeStartup;

