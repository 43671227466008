import React from 'react';
import {Link, useParams} from 'react-router-dom';
// import FooterOne from '../common/footer/FooterOne';
// import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import SplashFooter from '../common/footer/SplashFooter';
import HeaderTwo from '../common/header/HeaderTwo';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
        <SEO title="Project Details"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo />
            <BcrumbBannerTwo 
            title={detailsProject.title}
            paragraph ={detailsProject.excerpt}
            mainThumb={detailsProject.image}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                            </div>
                            {detailsProject.body.map((para, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: para}}></p>
                            ))}
                            <Link to="/contact" className="axil-btn btn-fill-primary">Get it Now</Link>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">We delivered</h3>
                                    <p>Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business.</p>
                                </div>
                                <Accordion defaultActiveKey="1">
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										<FaCompress /> Strategy
									</Accordion.Header>
									<Accordion.Body>
                                    Focusing on a overall system, We
												consolidate your website with
												other means to sucessfully reach
												your customers. A few of these
												approaches are a good User
												experience, and also reaching
												out to the customers via other
												chanels, such as emails.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>
										<FaCode /> Design
									</Accordion.Header>
									<Accordion.Body>
										More than a beautiful page, design for
										us is an identity, A digital thumbprint
										that is unique to your business. Our
										intention is to find out more about you,
										your business and your target audience
										and the message you want to convey
										through design.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>
										<FaGlobe /> Development
									</Accordion.Header>
									<Accordion.Body>
										Depending on your business goals and
										scale, we offer tools and technologies
										that will give you enough capabilities
										to grow your business. We offer a wide
										range of solutions from simple static
										websites to complex web applications.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <VideoOne />
            <CtaLayoutOne />
<SplashFooter />
        </main>
        </>
    )
}

export default ProjectDetails;