import React, { useEffect } from 'react';

const PreloadImages = ({ images }) => {
  useEffect(() => {
    images.forEach(image => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = `${process.env.PUBLIC_URL}${image.width_img}`;
      document.head.appendChild(link);
    });

    return () => {
      images.forEach(image => {
        const preloadLink = document.querySelector(`link[rel="preload"][href="${process.env.PUBLIC_URL}${image.width_img}"]`);
        if (preloadLink) document.head.removeChild(preloadLink);
      });
    };
  }, [images]); 

  return null; 
};

export default PreloadImages;
