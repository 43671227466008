import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpOne = () => {
	return (
		<div className="section section-padding bg-color-dark">
			<div className="container">
				<SectionTitle
					subtitle="Small team, big ideas"
					title="Great Design is for everyone"
					description="We have helped individuals build their portfolios and their small businesses presence. We have helped dozens of startups acquire users, and build their brand. We have also helped big companies with their big projects and small budgets."
					textAlignment="heading-light"
					textColor=""
				/>
				<div className="row">
					<CounterUp
						colSize="col-lg-3 col-6"
						layoutStyle=""
						evenTopMargin=""
					/>
				</div>
			</div>
		</div>
	);
};

export default CounterUpOne;
