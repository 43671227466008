import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
	return (
		<div className="section section-padding case-study-featured-area">
			<div className="container">
				<div className="row">
                <div className="col-xl-6 col-lg-6">
						<div className="case-study-featured">
							<div className="section-heading heading-left">
								<span className="subtitle">
									Why User is the King
								</span>
								<h2 className="title">
									Building software for world changers
								</h2>
								<p>
									At TekDislay we firmly belive what makes
									business is great people. That's why we
									build solutions for people thinking of
									people. We
									create the best solutions that will
									drive business and make both companies and users sucessful.
								
								</p>
								<p>
									It's a happy medium and we found the
									correct recipe for it.
								</p>
							</div>

						</div>
					</div>
					<div className="col-xl-6 col-lg-6">
						{/* <div className="case-study-featured-thumb text-start"> */}
                        
							{/* <img
								src={
									process.env.PUBLIC_URL +
									"/images/others/case-study-4.png"
								}
								alt="travel"
							/> */}
						{/* </div> */}
                        <div className="case-study-counterup">
								<div className="single-counterup">
									<h2 className="count-number">
										<TrackVisibility once>
											{({ isVisible }) => (
												<span className="number count">
													{isVisible ? (
														<CountUp
															end="10"
															duration={1}
														/>
													) : null}
												</span>
											)}
										</TrackVisibility>
										<span className="symbol">+</span>
									</h2>
									<span className="counter-title">
										Years on the market
									</span>
								</div>
								<div className="single-counterup">
									<h2 className="count-number">
										<TrackVisibility once>
											{({ isVisible }) => (
												<span className="number count">
													{isVisible ? (
														<CountUp
															end="32"
															duration={1}
														/>
													) : null}
												</span>
											)}
										</TrackVisibility>
										<span className="symbol">+</span>
									</h2>
									<span className="counter-title">
										Projects delivered so far
									</span>
								</div>
                                
							</div>
                            <Link style={{marginTop: '10em'}}
									to="/contact"
									className="axil-btn btn-fill-primary btn-large">
									Work with us
								</Link>
                            
					</div>
                    
				</div>
			</div>
		</div>
	);
};

export default AboutFour;
