import React from "react";
import { Link } from "react-router-dom";
// import { FaFacebookF} from "react-icons/fa";
// import { FaBehance } from "react-icons/fa";

const SplashFooter = () => {
	return (
		<footer className="footer-area splash-footer">
			<div className="container">
				<div className="footer-bottom">
					<div className="row align-items-center">
						<div className="col-lg-5">
							<div className="footer-copyright">
								<span className="copyright-text">
									© {new Date().getFullYear()}. All rights
									reserved by{" "}
									<a href="https://tekdisplay.com/">
										TekDisplay
									</a>
									.
								</span>
							</div>
						</div>
						<div className="col-lg-2">
							<ul className="footer-social list-unstyled">
								{/* <li>
									<a href="https://www.facebook.com/">
										<FaFacebookF />
									</a>
								</li> */}
								{/* <li><Link to="https://www.behance.net/loveclari"><FaBehance /></Link></li> */}
							</ul>
						</div>
						<div className="col-lg-5">
							<div className="footer-bottom-link">
								<ul className="list-unstyled">
									<li>
										<Link
											to={
												process.env.PUBLIC_URL +
												"/contact"
											}>
											Contact Us
										</Link>
									</li>
									<li>
										<Link
											to={
												process.env.PUBLIC_URL +
												"/privacy-policy"
											}>
											Privacy Policy
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default SplashFooter;
