import React from "react";
// import { Link } from "react-router-dom";
import SplashFooter from "../common/footer/SplashFooter";
import ServicePropOne from "../component/service/ServicePropOne";
// import SectionTitle from "../elements/section-title/SectionTitle";
import SEO from "../common/SEO";
import SplashBanner from "../component/banner/SplashBanner";
import SplashCta from "../component/cta/SplashCta";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SplashData from "../data/splash/SplashData.json";
// import { slugify } from "../utils";
import HeaderTwo from "../common/header/HeaderTwo";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight as faArrowRight } from "@fortawesome/free-solid-svg-icons";
// // import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// const DemoData = SplashData[0];
const FeatureData = SplashData[1];

const Splash = () => {
	return (
		<>
			<SEO title="Wed Design and Web Development Agency" />
			<ColorSwitcher />
			<main className="main-wrapper">
				<HeaderTwo />
				<SplashBanner />

				<div
					className="section main-demo-area bg-color-light"
					id="splash-demo">
					<section className="service-three-area pt-70 rpt-40 rel z-2">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-7">
									<div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
										<span className="sub-title mb-20">
											Some of our skillset
										</span>
										<h2>
											We Provide Amazing Web Design
											Solutions
										</h2>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-xl-3 col-lg-4 col-md-6">
									<div className="service-three-item wow fadeInUp delay-0-2s">
										<div className="title-icon">
											<h3>
												<a href="service-details/web-design">
													Web Design From Figma
												</a>
											</h3>
											<img
												src="/images/icon1.png"
												alt="Icon"
											/>
										</div>
										<div className="content">
											<p>
												Transforming visions into
												reality with expert Web Design
												skills honed in Figma, where
												creativity meets precision.
											</p>
											<a
												className="read-more style-two"
												href="http://tekdisplay.com/service-details/web-design">
												<span>Design</span>{" "}
												{/* <FontAwesomeIcon
													icon={faArrowRight}
													className="far fa-arrow-right"
												/> */}
												<i className="far fa-arrow-right"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-6">
									<div className="service-three-item wow fadeInDown delay-0-2s">
										<div className="title-icon">
											<h3>
												<a href="service-details/web-design">
													PSD, Figma, XD to HTML
													Convert
												</a>
											</h3>
											<img
												src="/images/icon2.png"
												alt="Icon"
											/>
										</div>
										<div className="content">
											<p>
												Seamlessly converting PSD,
												Figma, and Adobe XD designs into
												pixel-perfect HTML, bridging the
												gap between visual concepts and
												functional web realities.
											</p>
											<a
												className="read-more style-two"
												href="service-details/web-design">
												<span>Web Design</span>{" "}
												{/* <FontAwesomeIcon
													icon={faArrowRight}
												/> */}
												<i className="far fa-arrow-right"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-6">
									<div className="service-three-item wow fadeInUp delay-0-2s">
										<div className="title-icon">
											<h3>
												<a href="/digital-agency">
													CMS Development
												</a>
											</h3>
											<img
												src="/images/icon3.png"
												alt="Icon"
											/>
										</div>
										<div className="content">
											<p>
												Experience with various CMS
												systems including WordPress
												development, Shopify, and more.
												Turning design into something
												unique.
											</p>
											<a
												className="read-more style-two"
												href="/digital-agency">
												<span>CMS Development</span>{" "}
												{/* <FontAwesomeIcon
													icon={faArrowRight}
												/> */}
												<i className="far fa-arrow-right"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-6">
									<div className="service-three-item wow fadeInDown delay-0-2s">
										<div className="title-icon">
											<h3>
												<a href="/digital-agency">
													NextJS and React Development
												</a>
											</h3>
											<img
												src="/images/icon/nextjs-icon.svg"
												alt="Icon"
											/>
										</div>
										<div className="content">
											<p>
												Revolutionizing web experiences
												with React development,
												transforming interactive designs
												into fast, responsive, and
												scalable applications.
											</p>
											<a
												className="read-more style-two"
												href="/digital-agency">
												<span>Complex Apps</span>{" "}
												<i className="far fa-arrow-right"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* <div className="container">
						<div className="section-heading heading-left">
							<div className="row align-items-center">
								<div className="col-xl-6 col-lg-7">
									<h2 className="title">
										Our templates are just ready to use
									</h2>
								</div>
								<div className="col-xl-4 col-lg-5 offset-xl-2">
									<p>
										You will love all of the features in our
										Template. 100% guaranteed satisfaction.
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							{DemoData.map((data) => (
								<div className="col-md-6" key={data.id}>
									<div className="single-demo">
										<Link
											to={`${
												process.env.PUBLIC_URL
											}/${slugify(data.title)}`}>
											<span className="thumb">
												<img
													src={`${process.env.PUBLIC_URL}${data.height_img}`}
													alt={data.title}
												/>
											</span>
											<h4 className="title">
												{data.title}
											</h4>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div> */}
					<ul className="shape-group list-unstyled">
						<li className="shape-2">
							<img
								src={
									process.env.PUBLIC_URL +
									"/images/others/line-4.png"
								}
								alt="Shape"
							/>
						</li>
					</ul>
				</div>

				<div
					className="section section-padding bg-color-dark splash-features"
					id="splsh-features">
					<div className="container">
						<div className="section-heading heading-light-left">
							<div className="row align-items-center">
								<div className="col-lg-6">
									<h2 className="title">
										From Concept to Creation: Our Skilled
										Journey
									</h2>
								</div>
								<div className="col-lg-5 col-md-8">
									<p>
										Expert Skills for Extraordinary Results.
										Unveiling Our Toolbox: Skills That Set
										Us Apart.
									</p>
								</div>
							</div>
						</div>

						<div className="container">
							<div className="row">
								<ServicePropOne
									colSize="col-xl-4 col-md-6"
									serviceStyle=""
									itemShow="6"
								/>
							</div>
						</div>
						<ul className="list-unstyled shape-group-10">
							<li className="shape shape-1">
								<img
									src={
										process.env.PUBLIC_URL +
										"/images/others/line-9.png"
									}
									alt="Circle"
								/>
							</li>
							<li className="shape shape-2">
								<img
									src={
										process.env.PUBLIC_URL +
										"/images/others/bubble-42.png"
									}
									alt="Circle"
								/>
							</li>
						</ul>

						{/* <div className="row">
							{FeatureData.slice(0, 9).map((data) => (
								<div
									className="col-xl-4 col-md-6"
									key={data.id}>
									<div className="services-grid">
										<div className="thumbnail">
											<img
												src={
													process.env.PUBLIC_URL +
													data.icon
												}
												alt="icon"
											/>
										</div>
										<div className="content">
											<h5
												className="title"
												dangerouslySetInnerHTML={{
													__html: data.title,
												}}></h5>
											<p>{data.para}</p>
										</div>
									</div>
								</div>
							))}
						</div> */}
					</div>
					<ul className="list-unstyled shape-group-10">
						<li className="shape shape-2">
							<img
								src={
									process.env.PUBLIC_URL +
									"/images/others/line-3.png"
								}
								alt="Circle"
							/>
						</li>
					</ul>
				</div>

				<div
					className="section section-padding bg-color-light spalsh-why-choose"
					id="splash-why-choose">
					<div className="container">
						<div className="section-heading heading-left">
							<div className="row align-items-center">
								<div className="col-xl-6 col-lg-7">
									<h2 className="title">Why work with us?</h2>
								</div>
								<div className="col-xl-4 col-lg-5 offset-xl-2">
									<p>
										Delivering customized solutions that not
										only meet but surpass your expectations.
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							{FeatureData.slice(9, 12).map((data) => (
								<div
									className="col-xl-4 col-lg-6"
									key={data.id}>
									<div className="why-buy-box">
										<div className="heading">
											<div className="icon">
												<img
													src={
														process.env.PUBLIC_URL +
														data.icon
													}
													alt="Thumb"
												/>
											</div>
											<h5
												className="title"
												dangerouslySetInnerHTML={{
													__html: data.title,
												}}></h5>
										</div>
										<p>{data.para}</p>
									</div>
								</div>
							))}
						</div>
						{/* 
						<div className="row justify-content-center">
							<div className="col-xl-4 col-lg-6">
								<div className="support-box online-docuentation splash-hover-control">
									<a href="https://new.axilthemes.com/docs/TekDisplay-react/">
										<div className="inner">
											<div className="content">
												<div className="heading">
													<h4 className="title">
														Code
														<br /> Documentation
													</h4>
													<div className="icon">
														<img
															src={
																process.env
																	.PUBLIC_URL +
																"/images/icon/icon-25.png"
															}
															alt="Thumb"
														/>
													</div>
												</div>
												<p>
													Well organized and up to
													date
												</p>
											</div>
											<div className="btn-area">
												<span className="item-btn">
													<FaAngleRight />
												</span>
											</div>
										</div>
										<ul className="shape-group list-unstyled">
											<li className="shape-1">
												<img
													src={
														process.env.PUBLIC_URL +
														"/images/others/bubble-34.png"
													}
													alt="Shape"
												/>
											</li>
											<li className="shape-2">
												<img
													src={
														process.env.PUBLIC_URL +
														"/images/others/line-8.png"
													}
													alt="Shape"
												/>
											</li>
										</ul>
									</a>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6">
								<div className="support-box support-ticket splash-hover-control">
									<a href="https://support.axilthemes.com/support/">
										<div className="inner">
											<div className="content">
												<div className="heading">
													<h4 className="title">
														Submit A <br /> Support
														Ticket
													</h4>
													<div className="icon">
														<img
															src={
																process.env
																	.PUBLIC_URL +
																"/images/icon/icon-26.png"
															}
															alt="Thumb"
														/>
													</div>
												</div>
												<p>
													We response within 1
													Business day. weekends
													excluded.
												</p>
											</div>
											<div className="btn-area">
												<span className="item-btn">
													<FaAngleRight />
												</span>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div> */}
					</div>
					<div className="line-shape">
						<img
							src={
								process.env.PUBLIC_URL +
								"/images/others/line-6.png"
							}
							alt="Line"
						/>
					</div>
				</div>
				<SplashCta />
				<SplashFooter />
			</main>
		</>
	);
};

export default Splash;
