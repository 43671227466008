import React from 'react';

const Data = [
    {
        image: "/images/icon/storyboardart-logo.jpeg"
    },
    {
        image: "/images/icon/affimity.png"
    },
    {
        image: "/images/DYNAMOND-logo.webp"
    },
    {
        image: "/images/sknn-logo.png"
    },
    {
        image: "/images/dod-logo.png"
    },
    {
        image: "/images/ih-logo.png"
    },
    {
        image: "/images/gia-logo.svg"
    },
    {
        image: "/images/icon/aztrana.png"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;