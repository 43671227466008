import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProcesstData from "../../data/process/ProcessData.json";
import Tilty from "react-tilty";

const getProcesstData = ProcesstData;

const ProcessOne = () => {
    const extractTitlesFromParagraph = (paragraph) => {
        const sections = paragraph.split('. ');
      
        return sections.map(section => {
          const colonIndex = section.indexOf(':');
          const startIndex = section.lastIndexOf(' ', colonIndex - 1) + 1;
          const endIndex = (section.length) + (sections[sections.length - 1] === section ? 0 : 1);
          return colonIndex !== -1 ? section.substring(startIndex, endIndex) + '.' : null;
        }).filter(title => title);
      };
    

	return (
		<div className="section section-padding bg-color-light pb--70">
			<SectionTitle
				subtitle="Process"
				title="This is our Process."
				description="Each project at our agency follows a meticulously organized process, ensuring clear communication and timely execution to keep everything on track towards your digital success."
				textAlignment=""
				textColor="mb--90"
			/>
			<div className="container">
				{getProcesstData.map((data) => (
					<div
						key={data.id}
						className={`process-work ${
							data.id % 2 === 0 ? "content-reverse" : ""
						}`}>
						<Tilty perspective={2000}>
							<div className="thumbnail">
								<img
									src={process.env.PUBLIC_URL + data.thumb}
									alt="Thumbnail"
								/>
							</div>
						</Tilty>
						<div className="content">
							<span className="subtitle">{data.subtitle}</span>
							<h3 className="title">{data.title}</h3>
							{/* <p>{data.paragraph}</p> */}
                            <ul>
              {extractTitlesFromParagraph(data.paragraph).map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
						</div>
					</div>
				))}
			</div>
			<ul className="shape-group-17 list-unstyled">
				<li className="shape shape-2">
					<img
						src={
							process.env.PUBLIC_URL +
							"/images/others/bubble-23.png"
						}
						alt="Bubble"
					/>
				</li>
				<li className="shape shape-3">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-4.png"
						}
						alt="Line"
					/>
				</li>
				<li className="shape shape-4">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-5.png"
						}
						alt="Line"
					/>
				</li>
				<li className="shape shape-5">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-4.png"
						}
						alt="Line"
					/>
				</li>
				<li className="shape shape-6">
					<img
						src={
							process.env.PUBLIC_URL + "/images/others/line-5.png"
						}
						alt="Line"
					/>
				</li>
			</ul>
		</div>
	);
};

export default ProcessOne;
