import React from 'react';
// import HeaderThree from '../common/header/HeaderThree';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
// import ProjectTwo from '../component/project/ProjectTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
// import BrandOne from '../component/brand/BrandOne';
// import ProjectThree from '../component/project/ProjectThree';
import TestimonialTwo from '../component/testimonial/TestimonialTwo';
import SplashFooter from '../common/footer/SplashFooter';
import FormThree from '../component/contact/FormThree';

const PersonalPortfolio = () => {

    return (
        <>
        <SEO title="Personal Portfolio"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo/>
            <BannerThree />
            {/* <ProjectTwo /> */}
            {/* <ProjectThree /> */}

            {/* <BrandOne /> */}
            <TestimonialTwo />

            <div className="section-padding bg-color-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Need a Web Designer?"
                                title="Let’s work together"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className="address-list">
                                <div className="address">
                                <h6 className="title">Mail</h6>
                                <p>contact@tekdisplay.com</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Address</h6>
                                <p>San Francisco and Salt Lake City</p>
                                </div>
                                {/* <div className="address">
                                <h6 className="title">Phone</h6>
                                <p>+16506057777</p>
                                </div> */}
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Describe your project</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SplashFooter/>

        </main>
        </>
    )
}

export default PersonalPortfolio;

