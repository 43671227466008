import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import SplashFooter from "../common/footer/SplashFooter";

const PrivacyPolicy = () => {
	return (
		<>
			<SEO title="Privacy Policy" />
			<ColorSwitcher />
			<main className="main-wrapper">
				<HeaderOne />
				<BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
				<div className="section-padding privacy-policy-area">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="privacy-policy-content">
									<div className="section-title">
										<h5 className="title">
											This Privacy policy was published on
											March 22nd, 2024.
										</h5>
									</div>
									<h4>GDPR compliance</h4>
									<p>
										We are fully committed to upholding your
										privacy rights and ensuring the
										protection of your personal data in
										accordance with the General Data
										Protection Regulation (GDPR). Our
										practices are designed to provide a
										secure and respectful environment for
										handling your personal information.
									</p>
									<p>
										Our commitment under GDPR includes
										ensuring the lawful processing of
										personal data, maintaining transparency
										about data processing practices, and
										safeguarding the security of your
										information. We collect personal data
										only for specified, explicit, and
										legitimate purposes and only to the
										extent that it is necessary for those
										purposes.
									</p>
									<p>
										You, as a data subject, have several
										rights under GDPR, including the right
										to access, rectify, erase, restrict
										processing of, or object to the
										processing of your personal data, as
										well as the right to data portability.
										We respect these rights and provide
										mechanisms for you to exercise them.
									</p>
									<p>
										For more information on how we process
										your personal data, how we keep it
										secure, and how you can exercise your
										rights under GDPR, please read our
										Privacy Policy. Should you have any
										concerns about your personal data's
										privacy and security, or if you wish to
										exercise any of your rights, please
										contact us directly.
									</p>
									<h4>About TekDisplay</h4>
									<p>
										At TekDisplay, we specialize in
										delivering cutting-edge visual display
										solutions that cater to a wide range of
										needs. Our commitment to innovation and
										quality drives us to offer
										state-of-the-art products that not only
										meet but exceed our clients'
										expectations.
									</p>
									<p>
										Our team is composed of experts in the
										field of visual technology, dedicated to
										providing personalized service and
										support to ensure that every project is
										a success. Whether you're looking to
										enhance your corporate communication,
										boost your retail marketing, or engage
										students in a learning environment,
										TekDisplay has the expertise and
										technology to bring your vision to life.
									</p>
									<p>
										We pride ourselves on our
										customer-centric approach, focusing on
										creating tailored solutions that align
										with our clients' specific objectives.
										For more information about our products
										and services, or to discuss your next
										project, please reach out to us at{" "}
										<a href="mailto:contact@TekDisplay.com">
											contact@TekDisplay.com
										</a>
										.
									</p>{" "}
									{/* <h4>
										When we collect personal data about you
									</h4>
									<ul>
										<li>
											Email is a crucial channel in any
											marketing.
										</li>
										<li>
											Curious what to say? How to say it?
										</li>
										<li>
											Whether you’re kicking off a new
											campaign.
										</li>
										<li>
											Habitasse per feugiat aliquam luctus
											accumsan curae
										</li>
									</ul> */}
									<h4>
										Why we collect and use personal data
									</h4>
									<p className="mb--20">
										Our primary reason for collecting
										personal data, specifically email
										addresses, is to establish contact with
										potential leads. This allows us to:
									</p>
									<ul>
										<li>
											Communicate Effectively: By
											collecting email addresses, we can
											directly communicate with you,
											providing updates, information, and
											responses to your inquiries promptly
											and efficiently.
										</li>
										<li>
											Tailor Our Services: Understanding
											your needs and interests helps us to
											tailor our communications and
											services. Through emails, we can
											send you relevant information that
											matches your preferences and
											requirements.
										</li>
										<li>
											Build Relationships: Email
											communication is a vital tool for us
											to build and maintain relationships
											with our clients and potential
											leads. It enables us to stay in
											touch, share valuable insights, and
											keep you informed about our latest
											offerings or changes.
										</li>
										<li>
											Enhance Our Services: Feedback and
											interactions through email are
											invaluable for improving our
											services. They help us to understand
											what works well and what needs
											refinement.
										</li>
										<li>
											Ensure Privacy and Security: We are
											committed to protecting your
											personal information. Collecting
											emails through secure channels
											allows us to safeguard your data
											effectively and ensures that our
											communications with you are
											confidential.
										</li>
									</ul>
									<p>
										We collect this data with the utmost
										respect for your privacy and in
										compliance with relevant data protection
										regulations. Our goal is to use this
										information to provide a personalized
										and secure experience for every user.
									</p>
									<h4>Type of personal data collected</h4>
									<p>
										Our organization is committed to
										transparency in the collection and use
										of your personal data. The types of
										personal data we collect may include,
										but are not limited to:
									</p>
									<ul>
										<li>
											<strong>
												Contact Information:
											</strong>{" "}
											Names, addresses, email addresses,
											and phone numbers, to stay in touch
											and provide updates or support.
										</li>
										<li>
											<strong>
												Professional Details:
											</strong>{" "}
											Job titles, roles, company names,
											and industry sectors, to offer
											tailored products or services.
										</li>
										<li>
											<strong>
												Technical Information:
											</strong>{" "}
											IP addresses, browser types, and
											operating system details, to ensure
											our website and services work
											effectively for everyone.
										</li>
										<li>
											<strong>User Preferences:</strong>{" "}
											Interests, feedback, and survey
											responses, to improve our offerings
											and communications.
										</li>
									</ul>
									<p>
										This data helps us to better understand
										our clients' needs, provide personalized
										services, and communicate effectively.
										We are dedicated to ensuring the privacy
										and security of all personal data
										collected, in compliance with applicable
										laws and regulations.
									</p>
									<h4>
										Information we collect automatically
									</h4>
									<p>
										As you interact with our services, we
										automatically collect certain types of
										information to help us understand how
										our services are used and to improve
										user experience. This automatic data
										collection includes:
									</p>
									<ul>
										<li>
											<strong>Web Usage Data:</strong>{" "}
											This includes details of your visits
											to our website, such as page
											response times, download errors,
											length of visits to certain pages,
											page interaction information (such
											as scrolling, clicks, and
											mouse-overs), and methods used to
											browse away from the page.
										</li>
										<li>
											<strong>Device Information:</strong>{" "}
											We collect information about the
											device you use to access our
											services, including the type of
											device, its operating system,
											browser type, unique device
											identifiers, and mobile network
											information.
										</li>
										<li>
											<strong>Location Data:</strong>{" "}
											Depending on your device settings,
											we may collect data about your
											location to offer location-specific
											services or content. This is
											generally derived from your IP
											address or data that indicates where
											you are located with a less precise
											degree of accuracy (e.g., at a city
											level).
										</li>
										<li>
											<strong>
												Cookies and Tracking
												Technologies:
											</strong>{" "}
											We use cookies and similar tracking
											technologies to track activity on
											our services and hold certain
											information. Cookies help us refine
											our service offerings and provide a
											more personalized experience.
										</li>
									</ul>
									<p>
										This information helps us to better
										tailor our services to our users' needs,
										improve service performance and user
										experiences, and detect and prevent
										security threats. We are committed to
										transparency and respect for your
										privacy in all our data collection
										practices.
									</p>
									<h4>The use of cookies and web beacons</h4>
									<p className="mb--20">
										We may log information using "cookies."
										Cookies are small data files stored on
										your hard drive by a website. Cookies
										help us make our Site and your visit
										better.
									</p>
									<p className="mb--20">
										may log information using digital images
										called web beacons on our Site or in our
										emails.
									</p>
									<p>
										This information is used to make our
										Site work more efficiently, as well as
										to provide business and marketing
										information to the owners of the Site,
										and to gather such personal data as
										browser type and operating system,
										referring page, path through site,
										domain of ISP, etc. for the purposes of
										understanding how visitors use our Site.
										Cookies and similar technologies help us
										tailor our Site to your personal needs,
										as well as to detect and prevent
										security threats and abuse. If used
										alone, cookies and web beacons do not
										personally identify you.
									</p>
									<h4>How long we keep your data</h4>
									<p className="mb--20">
										We store personal data for as long as we
										find it necessary to fulfill the purpose
										for which the personal data was
										collected, while also considering our
										need to answer your queries or resolve
										possible problems. This helps us to
										comply with legal requirements under
										applicable laws, to attend to any legal
										claims/complaints, and for safeguarding
										purposes.
									</p>
									<p>
										We store personal data for as long as we
										find it necessary to fulfill the purpose
										for which the personal data was
										collected, while also considering our
										need to answer your queries or resolve
										possible problems. This helps us to
										comply with legal requirements under
										applicable laws, to attend to any legal
										claims/complaints, and for safeguarding
										purposes.
									</p>
									<h4>Your rights to your personal data</h4>
									<p>
										We store personal data for as long as we
										find it necessary to fulfill the purpose
										for which the personal data was
										collected, while also considering our
										need to answer your queries or resolve
										possible problems. This helps us to
										comply with legal requirements under
										applicable laws, to attend to any legal
										claims/complaints, and for safeguarding
										purposes.
									</p>
									{/* <h4>Hotjar’s privacy policy</h4>
									<p>
										Pellentesque vestibulum venenatis
										iaculis. Aliquam viverra sodales
										ultrices. Quisque sed purus id massa
										consequat consectetur eu vel lorem.
										Maecenas lectus velit, cursus quis orci
										non, laoreet hendrerit mi. Nulla vitae
										ipsum fringilla, placerat metus eu,
										malesuada velit. Quisque viverra risus
										ex. Aenean commodo vestibulum efficitur.
										Nullam ligula orci, aliquet sed luctus
										vel, luctus vel dui. Sed pulvinar, ipsum
										at mattis imperdiet, diam augue tempor
										diam, sed porttitor odio elit ut ante.
										In posuere mi at mi pellentesque ornare
										sit amet gravida nisi. Praesent ac
										blandit odio. Curabitur iaculis ante
										elit, et imperdiet leo mollis at.
									</p> */}
									<h4>Changes to this Privacy Policy</h4>
									<p>
										We reserve the right to update or modify
										this Privacy Policy at any time to
										reflect changes in our practices,
										service offerings, or legal obligations.
										Any amendments to this policy will be
										posted on our website and, where
										appropriate, notified to you by email.
										We encourage you to regularly review
										this policy to stay informed about how
										we are protecting the personal data we
										collect. Your continued use of our
										services after any changes or revisions
										to this Privacy Policy signifies your
										agreement with the terms of the revised
										policy.{" "}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CtaLayoutOne />
				<SplashFooter />
			</main>
		</>
	);
};

export default PrivacyPolicy;
