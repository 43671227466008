import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import FsLightbox from "fslightbox-react";

const VideoOne = () => {
	const [toggler, setToggler] = useState(true);

	return (
		<>
			<div className="section section-padding-equal bg-color-light">
				<div className="container">
					<div className="about-expert">
						<div className="thumbnail">
							<img
								src={
									process.env.PUBLIC_URL +
									"/images/banner/Screen Shot 2024-01-14 at 5.41.08 PM.png"
								}
								alt="Thumbnail"
							/>
							<div className="popup-video">
								<button
									className="play-btn"
									onClick={() => setToggler(!toggler)}>
									<FaPlay />
								</button>
							</div>
						</div>
					</div>
				</div>
				<ul className="shape-group-16 list-unstyled">
					<li className="shape shape-1">
						<img
							src={
								process.env.PUBLIC_URL +
								"/images/others/circle-2.png"
							}
							alt="circle"
						/>
					</li>

				</ul>
			</div>
			<FsLightbox
				toggler={toggler}
				sources={["https://www.youtube.com/watch?v=Q4Kh2H2qWdc"]}
			/>
		</>
	);
};

export default VideoOne;
